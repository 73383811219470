@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Nunito', sans-serif;
}

.pages {
  color: #222222;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 2%;
}

@font-face {
  font-family: 'fourche-sc';
  src: local('fourche-sc'), url(/static/media/fourche-sc.58f4b6e2.woff) format('woff');
}

.background {
  background-color: #222222;
  min-height: 100vh;
  height: 100vh;
}


.navbar {
  background-color: #161616;
  display: flex;
  height: 70px;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  flex-wrap: nowrap;
  z-index: 10;
}

.nav-logo {
  color:  #e54f5b;
  text-decoration: none;
  font-size: 200%;
  font-family: fourche-sc;
}

.nav-logo:hover {
  color: #e54f5b;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  margin-right: 1%;
  padding-top: 3%;
  width: 100%;
}

.nav-menu .active {
  color: #e54f5b;
}

.nav-links{
  display: flex;
  color: white;
  font-size: 18px;
  height: 100%;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}

.nav-links:hover {
  color: #e54f5b;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.nav-item {
  line-height: 50%;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35vh;
    position: absolute;
    top: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-x: hidden;
    position: fixed;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.2s ease;
    z-index: 1;
    width: 100%;
    position: absolute;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding: 2rem;
  }

  .nav-links:hover {
    background-color: #5f5f5f;
    color: #000000;
  }

  .nav-logo {
    color:  #e54f5b;
    text-decoration: none;
    font-size: 155%;
    font-family: fourche-sc;
  }

  .nav-logo:hover {
    color: #e54f5b;
    text-decoration: none;
  }

  .hamburger {
    display: inline;
    display: initial;
    position: absolute;
    top: 18px;
    right: 22px;
  }

}

.footer{
  padding: 1%;
  background-color: #161616;
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
}

.footeritems p {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
  line-height: 2%;
}

.footeritems a {
  color: #e54f5b;
}

.footeritems a:hover {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
  line-height: 2%;
}

.gallery {
  background-color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 14%;
}

.slider {
  order: 1;
  margin-left: 20%;
  width: 60%;
  height: 40%;
  --wheelwidth: 60%;
  --wheelheight: 40%;
}

.text {
    order: 2;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-size: 30%;
    color: #ffffff;
    line-height: normal;
    padding-top: 25%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 18%;
    -webkit-column-gap: 18%;
            column-gap: 18%
}

.before {
    margin-left: 18%;
}

.subtext {
    order: 3;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-size: 25%;
    color: #e54f5b;
    line-height: 40%;
}

.subtext a {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
}

.subtext a:hover {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
}

@media screen and (min-width:1250px){
  .slider {
    --wheelwidth: 70%;
    --wheelheight: 60%;
  }
  .text {
      padding-top: 36%;
  }
}

@media screen and (min-width:1800px){
  .text {
      padding-top: 30%;
  }
}

@media screen and (min-width:2000px){
  .text {
      padding-top: 25%;
  }
}

@media screen and (max-width:1250px){
  .text {
      padding-top: 30%;
  }
}

@media screen and (max-width:1020px){
  .text {
      padding-top: 35%;
  }
}


@media screen and (max-width:900px){
  .text {
      padding-top: 40%;
  }
}


@media screen and (max-width: 768px) {

  .slider {
    order: 1;
    margin-left: 3%;
    padding-top: 20%;
    --wheelwidth: 95%;
    --wheelheight: 30%;
    --navsize: 30;
  }

  .text {
    padding-top: 60%;
    grid-column-gap: 25%;
    -webkit-column-gap: 25%;
            column-gap: 25%
  }

  .before {
      margin-left: 0%;
  }

  .subtext {
    padding-top: 2%;

  }

}

.ContactForm {
  background-color: #222222;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 50%;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 14%;
  grid-gap: 50%;
  gap: 50%;
  padding-top: 3%;
}

form {
  background: #222222;
}

.formRow {
  margin: 2%
}

.formInput{
  background-color: #222222 !important;
  border: 1px solid #e54f5b !important;
  color: #aaaaaa !important;
}

.formInput:focus {
  background-color: #222222;
  outline: none;
  box-shadow: 0 0 0 3px #e54f5b !important;
  color: #333333;
}

.formInput::-webkit-input-placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  outline: none;
}

.formInput:-ms-input-placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  outline: none;
}

.formInput::placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  outline: none;
}

.formInput:focus::-webkit-input-placeholder {
  color: #222222;
  opacity: 1;
}

.formInput:focus:-ms-input-placeholder {
  color: #222222;
  opacity: 1;
}

.formInput:focus::placeholder {
  color: #222222;
  opacity: 1;
}

.submit-btn {
  background-color: #222222;
  font-family: 'fourche-sc', sans-serif;
  color: #e54f5b !important;
  font-size: 75%;
  padding: 1% 5%;
  border-radius: 10px;
  border: 1px solid #e54f5b !important;
}

.submit-btn:hover {
  background-color: #e54f5b;
  color: #111111 !important;
  border: 2px solid #e54f5b;
}

.submit-btn:active {
  background-color: #222222;
  box-shadow: 0 1px #555;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.submit-btn:disabled:hover {
  background-color: #111111;
  color: #e54f5b !important;
  cursor: wait;
}

.errorMessage {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 10pt;
  color: #e54f5b;
  float: left;
  margin: 0;
}

#notifyToast {
  color: #111;
  border-radius: 6px;
  width: 75%;
  margin-left: 20%;
  background-color: #e54f5b;
  font-size: 75%;
  font-weight: bold;
}


.services {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: 10%;
  -webkit-column-gap: 10%;
          column-gap: 10%;
  width: 100%;
  padding-bottom: 14%;
  background-color: #222222;
}

.services img {
    order: 1;
    max-width: 25%;
    background-color: #222222;
}

.services p {
    order: 2;
    max-width: 45%;

    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    line-height: normal;
    font-size: 30%;
    color: #ffffff;
    background-color: #222222;
}

.services a {
    color: #ffffff;
}

.services a:hover {
    color: #ffffff;
}



@media screen and (max-width: 768px) {

  .services {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    grid-column-gap: 10%;
    -webkit-column-gap: 10%;
            column-gap: 10%;
    width: 100%;
    padding-bottom: 25%;
    background-color: #222222;
  }

  .services img {
      order: 1;
      max-width: 25%;
      background-color: #222222;
  }

  .services p {
      order: 2;
      max-width: 45%;

      text-align: left;
      font-family: 'Rubik', sans-serif;
      font-weight: normal;
      line-height: normal;
      font-size: 30%;
      color: #ffffff;
      background-color: #222222;
  }



}

