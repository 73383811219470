
.services {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 10%;
  width: 100%;
  padding-bottom: 14%;
  background-color: #222222;
}

.services img {
    order: 1;
    max-width: 25%;
    background-color: #222222;
}

.services p {
    order: 2;
    max-width: 45%;

    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    line-height: normal;
    font-size: 30%;
    color: #ffffff;
    background-color: #222222;
}

.services a {
    color: #ffffff;
}

.services a:hover {
    color: #ffffff;
}



@media screen and (max-width: 768px) {

  .services {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 10%;
    width: 100%;
    padding-bottom: 25%;
    background-color: #222222;
  }

  .services img {
      order: 1;
      max-width: 25%;
      background-color: #222222;
  }

  .services p {
      order: 2;
      max-width: 45%;

      text-align: left;
      font-family: 'Rubik', sans-serif;
      font-weight: normal;
      line-height: normal;
      font-size: 30%;
      color: #ffffff;
      background-color: #222222;
  }



}
