
.navbar {
  background-color: #161616;
  display: flex;
  height: 70px;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  width: 100%;
  flex-wrap: nowrap;
  z-index: 10;
}

.nav-logo {
  color:  #e54f5b;
  text-decoration: none;
  font-size: 200%;
  font-family: fourche-sc;
}

.nav-logo:hover {
  color: #e54f5b;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  text-align: center;
  margin-right: 1%;
  padding-top: 3%;
  width: 100%;
}

.nav-menu .active {
  color: #e54f5b;
}

.nav-links{
  display: flex;
  color: white;
  font-size: 18px;
  height: 100%;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
}

.nav-links:hover {
  color: #e54f5b;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.nav-item {
  line-height: 50%;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35vh;
    position: absolute;
    top: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-x: hidden;
    position: fixed;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.2s ease;
    z-index: 1;
    width: 100%;
    position: absolute;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
    padding: 2rem;
  }

  .nav-links:hover {
    background-color: #5f5f5f;
    color: #000000;
  }

  .nav-logo {
    color:  #e54f5b;
    text-decoration: none;
    font-size: 155%;
    font-family: fourche-sc;
  }

  .nav-logo:hover {
    color: #e54f5b;
    text-decoration: none;
  }

  .hamburger {
    display: initial;
    position: absolute;
    top: 18px;
    right: 22px;
  }

}
