.gallery {
  background-color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 14%;
}

.slider {
  order: 1;
  margin-left: 20%;
  width: 60%;
  height: 40%;
  --wheelwidth: 60%;
  --wheelheight: 40%;
}

.text {
    order: 2;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-size: 30%;
    color: #ffffff;
    line-height: normal;
    padding-top: 25%;
    display: flex;
    flex-direction: row;
    column-gap: 18%
}

.before {
    margin-left: 18%;
}

.subtext {
    order: 3;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    font-size: 25%;
    color: #e54f5b;
    line-height: 40%;
}

.subtext a {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
}

.subtext a:hover {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
}

@media screen and (min-width:1250px){
  .slider {
    --wheelwidth: 70%;
    --wheelheight: 60%;
  }
  .text {
      padding-top: 36%;
  }
}

@media screen and (min-width:1800px){
  .text {
      padding-top: 30%;
  }
}

@media screen and (min-width:2000px){
  .text {
      padding-top: 25%;
  }
}

@media screen and (max-width:1250px){
  .text {
      padding-top: 30%;
  }
}

@media screen and (max-width:1020px){
  .text {
      padding-top: 35%;
  }
}


@media screen and (max-width:900px){
  .text {
      padding-top: 40%;
  }
}


@media screen and (max-width: 768px) {

  .slider {
    order: 1;
    margin-left: 3%;
    padding-top: 20%;
    --wheelwidth: 95%;
    --wheelheight: 30%;
    --navsize: 30;
  }

  .text {
    padding-top: 60%;
    column-gap: 25%
  }

  .before {
      margin-left: 0%;
  }

  .subtext {
    padding-top: 2%;

  }

}
