.footer{
  padding: 1%;
  background-color: #161616;
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
}

.footeritems p {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
  line-height: 2%;
}

.footeritems a {
  color: #e54f5b;
}

.footeritems a:hover {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: #e54f5b;
  line-height: 2%;
}
