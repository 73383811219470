.App {
  font-family: 'Nunito', sans-serif;
}

.pages {
  color: #222222;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 2%;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@font-face {
  font-family: 'fourche-sc';
  src: local('fourche-sc'), url("./fonts/fourche-sc.woff") format('woff');
}

.background {
  background-color: #222222;
  min-height: 100vh;
  height: 100vh;
}
