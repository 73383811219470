.ContactForm {
  background-color: #222222;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 50%;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 14%;
  gap: 50%;
  padding-top: 3%;
}

form {
  background: #222222;
}

.formRow {
  margin: 2%
}

.formInput{
  background-color: #222222 !important;
  border: 1px solid #e54f5b !important;
  color: #aaaaaa !important;
}

.formInput:focus {
  background-color: #222222;
  outline: none;
  box-shadow: 0 0 0 3px #e54f5b !important;
  color: #333333;
}

.formInput::placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  outline: none;
}

.formInput:focus::placeholder {
  color: #222222;
  opacity: 1;
}

.submit-btn {
  background-color: #222222;
  font-family: 'fourche-sc', sans-serif;
  color: #e54f5b !important;
  font-size: 75%;
  padding: 1% 5%;
  border-radius: 10px;
  border: 1px solid #e54f5b !important;
}

.submit-btn:hover {
  background-color: #e54f5b;
  color: #111111 !important;
  border: 2px solid #e54f5b;
}

.submit-btn:active {
  background-color: #222222;
  box-shadow: 0 1px #555;
  transform: translateY(1px);
}

.submit-btn:disabled:hover {
  background-color: #111111;
  color: #e54f5b !important;
  cursor: wait;
}

.errorMessage {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 10pt;
  color: #e54f5b;
  float: left;
  margin: 0;
}

#notifyToast {
  color: #111;
  border-radius: 6px;
  width: 75%;
  margin-left: 20%;
  background-color: #e54f5b;
  font-size: 75%;
  font-weight: bold;
}
